import { createStore } from "vuex";

export default createStore({
  state: {
    phone: "",
    VerificationCode: "",
    user: {},
  },
  mutations: {
    setPhone(state, phone) {
      state.phone = phone;
    },
    setVerificationCode(state, code) {
      state.VerificationCode = code;
    },
    setUser(state, user) {
      state.user = user;
    },
  },
  actions: {},
  modules: {},
});
