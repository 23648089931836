import { createRouter, createWebHistory } from "vue-router";

// 公共路由
const public_router = [
  {
    path: "/",
    component: () => import("../views/login.vue"),
    meta: {
      navigator: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/login.vue"),
    meta: {
      navigator: false,
    },
  },
  {
    path: "/errorPage",
    name: "errorPage",
    component: () => import("../views/errorPage.vue"),
    meta: {
      navigator: false,
    },
  },
  {
    path: "/tipsPage",
    name: "tipsPage",
    component: () => import("../views/tipsPage.vue"),
    meta: {
      navigator: false,
    },
  },
  {
    // 注册路由
    path: "/register",
    name: "register",
    component: () => import("../views/register/index.vue"),
    meta: {
      navigator: false,
      twoSided: true,
    },
  },
  {
    // 输入信息
    path: "/registerSignUp",
    name: "registerSignUp",
    component: () => import("../views/register/signUp.vue"),
    meta: {
      navigator: false,
      twoSided: true,
    },
  },
  {
    // 输入信息
    path: "/TestTracking",
    name: "TestTracking",
    component: () => import("../components/TestTracking.vue"),
    meta: {
      navigator: false,
      twoSided: true,
    },
  },
  // {
  //   // 输入信息
  //   path: "/FaceDetection",
  //   name: "FaceDetection",
  //   component: () => import("../components/FaceDetection.vue"),
  //   meta: {
  //     navigator: false,
  //     twoSided: true,
  //   },
  // },
];

// PC路由
const pc_routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("../views/pc/home.vue"),
    meta: {
      isMobile: false,
      navigator: true,
    },
  },
  {
    path: "/signUp",
    name: "signUp",
    component: () => import("../views/pc/signUp.vue"),
    meta: {
      isMobile: false,
      navigator: false,
    },
  },
  {
    path: "/curriculum",
    name: "curriculum",
    component: () => import("../views/pc/curriculum/index.vue"),
    meta: {
      isMobile: false,
      navigator: true,
    },
  },
  {
    path: "/curriculumDetails",
    name: "curriculumDetails",
    component: () => import("../views/pc/curriculum/curriculumDetails.vue"),
    meta: {
      isMobile: false,
      navigator: true,
    },
  },
  {
    path: "/examination",
    name: "examination",
    component: () => import("../views/pc/examination/index.vue"),
    meta: {
      isMobile: false,
      navigator: true,
    },
  },
  {
    path: "/examinationDetails",
    name: "examinationDetails",
    component: () => import("../views/pc/examination/examinationDetails.vue"),
    meta: {
      isMobile: false,
      navigator: false,
    },
  },
  {
    path: "/certificate",
    name: "certificate",
    component: () => import("../views/pc/certificate/index.vue"),
    meta: {
      isMobile: false,
      navigator: true,
    },
  },
];

// 移动端路由
const mobile_router = [
  {
    path: "/operation",
    name: "operation",
    component: () => import("../views/mobile/operation.vue"),
    meta: {
      isMobile: true,
      navigator: false,
    },
  },
  {
    path: "/mobileHome",
    name: "mobileHome",
    component: () => import("../views/mobile/mobileHome.vue"),
    meta: {
      isMobile: true,
      navigator: false,
    },
  },
  {
    path: "/mobileCurriculum",
    name: "mobileCurriculum",
    component: () => import("../views/mobile/mobileCurriculum.vue"),
    meta: {
      isMobile: true,
      navigator: false,
    },
  },
  {
    path: "/mobileExamination",
    name: "mobileExamination",
    component: () => import("../views/mobile/mobileExamination.vue"),
    meta: {
      isMobile: true,
      navigator: false,
    },
  },
];

const routes = public_router.concat(pc_routes.concat(mobile_router));
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
const isMobile = navigator.userAgent.match(
  /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
)
  ? true
  : false;
const isWeixin =
  navigator.userAgent.toLowerCase().match(/MicroMessenger/i) == "micromessenger"
    ? true
    : false;
const loginInfo = sessionStorage.getItem("userInfo") && JSON.parse(sessionStorage.getItem("userInfo"));
router.beforeEach((to, from, next) => {
  if (to.path === "/errorPage") {
    next();
  } else {
    if (to.name != "login") {
      // 禁止跨端访问
      if (isMobile && !isWeixin) {
        if (isMobile && !isWeixin) {
          next({
            path: "/errorPage",
          });
        } else if (isMobile && !to.meta.isMobile && !to.meta.twoSided) {
          //  if (isMobile && !to.meta.isMobile && !to.meta.twoSided) {
          next({
            path: "/mobileHome",
          });
        } else if (!isMobile && to.meta.isMobile && !to.meta.twoSided) {
          next({
            path: "/home",
          });
        } else {
          next();
        }
      } else {
        next();
      }
    } else {
      next();
    }
  }
});

export default router;
