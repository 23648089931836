import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import https from "./request.js";
import Element from "element-plus";
import Vant from "vant";
import { Toast } from "vant";
import storage from "./assets/js/storage";
import "vant/lib/index.css";
import VideoPlayer from "vue-video-player/src";
import "vue-video-player/src/custom-theme.css";
import "video.js/dist/video-js.css";
import "element-plus/lib/theme-chalk/index.css";
import "./styles/app.css";
import "lib-flexible";

const hls = require("videojs-contrib-hls");
const app = createApp(App); // 创建实例
// app.config.globalProperties.$https = https;
app.config.globalProperties.$Toast = Toast;
app.config.globalProperties.$storage = storage;
app.config.globalProperties.$domainName = "https://tecsf.gdcoh.com/";
app.config.globalProperties._isMobile = () => {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
};
console.log(storage);
// console.log(app.config.globalProperties)
app
  .use(store)
  .use(router)
  .use(Element)
  .use(Vant)
  .use(VideoPlayer)
  .use(https)
  .use(hls)
  .mount("#app");
